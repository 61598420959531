@import '../scss/variables';

.game-counter {
  color: $white;
  &--header {
    &-turn {
      padding: 3rem 0;
    }
    &-clickable {
      padding: 2rem 0;
    }
    &-perturn {
      padding: 1.5rem 0;
    }
  }
}
