// colors
$theme-colors: (
  dark: #141631,
) !default;

$colors: (
  dark-bg: #2E3E58,
) !default;

$white: #FFFFFF;

// tables
$table-dark-color: $white;

$headings-color: $white;
$hr-border-color: rgba($white, 0.1);

$body-bg: map-get($theme-colors, dark);
